<template>
  <div class="login">
    <div class="info-box">
      <div class="info-title">营口市智慧教研平台</div>
      <div class="info-ftitle">
        <span class="ftitle">找回密码</span>
      </div>
      <div class="info-base">
        <div class="base-box">
          <div class="base-box-top">
            <div class="base-icon-box">
              <img class="base-icon" src="@/assets/user/ic_1.png" alt="" />
            </div>
            <el-input
              v-model="dataForm.phone"
              placeholder="请输入手机号码"
              @blur="checkForm('phone')"
              :style="{
                border: checkList.phone.checked ? '1px solid red' : '',
                'border-radius': checkList.phone.checked ? '3px' : '',
              }"
              type="number"
              :clearable="true"
              @keyup.enter.native="onReset"
            ></el-input>
          </div>
          <div class="base-box-error" v-if="checkList.phone.checked">
            {{ checkList.phone.msg }}
          </div>
        </div>

        <div class="base-box">
          <div class="base-box-top">
            <div class="base-icon-box">
              <img class="base-icon" src="@/assets/user/ic_2.png" alt="" />
            </div>
            <el-input
              v-model="dataForm.code"
              placeholder="请输入验证码"
              :clearable="true"
              @keyup.enter.native="onReset"
            ></el-input>
            <div class="base-btn" @click="getCode(4)">{{ codeName }}</div>
          </div>
        </div>
        <div class="base-box">
          <div class="base-box-top">
            <div class="base-icon-box">
              <img class="base-icon" src="@/assets/user/ic_3.png" alt="" />
            </div>
            <el-input
              v-model="dataForm.password"
              placeholder="请输入新密码"
              type="password"
              @blur="checkForm('password')"
              :style="{
                border: checkList.password.checked ? '1px solid red' : '',
                'border-radius': checkList.password.checked ? '3px' : '',
              }"
              :show-password="true"
              :clearable="true"
              @keyup.enter.native="onReset"
            ></el-input>
          </div>
          <div class="base-box-error" v-if="checkList.password.checked">
            {{ checkList.password.msg }}
          </div>
        </div>
        <div class="base-box">
          <div class="base-box-top">
            <div class="base-icon-box">
              <img class="base-icon" src="@/assets/user/ic_3.png" alt="" />
            </div>
            <el-input
              v-model="dataForm.newpassword"
              placeholder="请再次确认新密码"
              type="password"
              @blur="checkForm('newpassword')"
              :style="{
                border: checkList.newpassword.checked ? '1px solid red' : '',
                'border-radius': checkList.newpassword.checked ? '3px' : '',
              }"
              :show-password="true"
              @keyup.enter.native="onReset"
              :clearable="true"
            ></el-input>
          </div>
          <div class="base-box-error" v-if="checkList.phone.checked">
            {{ checkList.phone.msg }}
          </div>
        </div>
        <div class="base-agreement">
          <img
            class="agreement-icon"
            v-if="isAgreement"
            src="@/assets/user/ic_5.png"
            alt=""
            @click="checkedAgreement"
          />
          <img
            class="agreement-icon"
            v-else
            src="@/assets/user/ic_4.png"
            @click="checkedAgreement"
            alt=""
          />
          <span>请阅读并同意</span>
          <span class="agreement" @click="openXY">《平台会员协议》</span>
        </div>
        <div class="info-base-btn" @click="onReset" v-loading="loading">
          确认修改
        </div>
      </div>
    </div>
    <el-dialog
      title="会员协议"
      :visible.sync="dialogVisible"
      :append-to-body="true"
      width="1000px"
    >
      <div class="xy-box" v-html="businessInfo.agreement_user"></div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogVisible = false"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import login from "@/mixins/login.js";
import Api from "@/api";
import { isPhone } from "/utils/formRule.js";
import { mapState } from "vuex";
export default {
  mixins: [login],
  data() {
    return {
      dataForm: {
        phone: "",
        password: "",
        code: "",
        newpassword: "",
      },
      dialogVisible: false,
      rules: {
        phone: { require: true, validator: isPhone.bind({ name: "手机号码" }) },
        password: { require: true, message: "密码不能为空" },
        newpassword: { require: true, message: "再次输入密码不能为空" },
      },
    };
  },
  computed: {
    ...mapState(["businessInfo"]),
  },
  created() {
    this.initForm();
  },
  methods: {
    goLogin() {
      this.$router.replace({
        name: "login",
      });
    },
    openXY() {
      this.dialogVisible = true;
    },
    formCheck() {
      let bool = false;
      let key = ["phone", "password", "newpassword", "code"];
      key.forEach((item) => {
        if (this.checkList[item] && this.checkList[item].checked) {
          bool = true;
        } else if (!this.dataForm[item]) {
          bool = true;
        }
      });
      return bool;
    },
    onReset() {
      if (this.isAgreement) {
        return this.$message.error("请阅读会员协议");
      } else if (this.formCheck()) {
        return this.$message.error("请填写完整的找回密码信息");
      } else if (this.dataForm.password != this.dataForm.newpassword) {
        return this.$message.error("两次密码不一致");
      } else if (!this.dataForm.code) {
        return this.$message.error("请填写验证码");
      }
      this.loading = true;
      Api.ResetPwd({
        mobile: this.dataForm.phone,
        password: this.dataForm.password,
        smscode: this.dataForm.code,
      }).then((res) => {
        if (res.status == 1) {
          this.$message({
            message: "重置成功",
            type: "success",
          });
          this.goLogin();
          this.loading = false;
        } else {
          this.$message.error(res.msg);
          this.loading = false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-input__inner {
  border: none;
  flex: 1;
  height: 40px;
}
::v-deep input::-webkit-outer-spin-button,
::v-deep input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}
::v-deep .el-input__suffix {
  .el-icon-circle-close:before {
    font-size: 18px;
  }
}
::v-deep input[type="number"] {
  -moz-appearance: textfield !important;
}
.error {
  border: 1px solid red;
  border-radius: 3px;
}
.xy-box {
  min-height: 400px;
  max-height: 400px;
  overflow-y: auto;
  line-height: 30px;
}
.login {
  width: 1200px;
  height: 700px;
  background: url("../../assets/user/banner2.png") no-repeat;
  background-size: 100% 100%;
  position: relative;
  z-index: 10;
  display: flex;
  justify-content: flex-end;
  .info-box {
    margin-right: 98px;
    padding-top: 86px;
    box-sizing: border-box;
    width: 404px;
    .info-title {
      font-size: 44px;
      font-family: AliHYAiHei;
      font-weight: bold;
      color: #1d2088;
    }
    .info-ftitle {
      font-size: 24px;

      font-weight: bold;
      color: #1d2088;
      padding-top: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      .ftitle {
        border-bottom: 1px solid #1d2088;
        padding-bottom: 10px;
      }
    }
    .info-base {
      padding-top: 26px;
      width: 100%;
      display: flex;
      flex-direction: column;
      .base-box {
        width: 100%;
        display: flex;
        flex-direction: column;
        padding: 10px 0;
        border-bottom: 1px solid #ececec;
        .base-box-top {
          display: flex;
          align-items: center;
        }
        .base-box-error {
          color: rgb(245, 108, 108);
          font-size: 12px;
          padding: 4px 0 0 30px;
        }
        .base-icon-box {
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 10px;
          .base-icon {
            width: 18px;
            height: 18px;
          }
        }
        .base-btn {
          width: 96px;
          height: 16px;
          padding: 11px;
          font-size: 13px;

          font-weight: 400;
          color: #1d2088;
          display: flex;
          align-items: center;
          justify-content: center;
          border: 1.5px solid #1d2088;
          border-radius: 6px;
          cursor: pointer;
          margin-left: 10px;
        }
      }
      .base-agreement {
        width: 100%;
        font-size: 14px;
        font-weight: 400;
        color: #999999;
        display: flex;
        align-items: center;
        padding-top: 20px;
        .agreement-icon {
          width: 14px;
          height: 14px;
          margin-right: 12px;
          cursor: pointer;
        }
        .agreement {
          font-size: 14px;

          font-weight: 400;
          color: #1d2088;
          cursor: pointer;
        }
      }
      .info-base-btn {
        width: 100%;
        height: 58px;
        background: #1d2088;
        border-radius: 6px;
        font-size: 18px;

        font-weight: 400;
        color: #ffffff;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 54px;
        cursor: pointer;
      }
    }
  }
}
</style>
